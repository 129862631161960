import ApiBase from './api-base'
import { setCartHeaders } from './utils'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { notifyBugsnag } from '@/modules/services/bugsnag'

class Cart extends ApiBase {
  constructor () {
    super()

    this.url = '/v2.5/cart'
    this.setHeaders()
  }

  setHeaders ({ token } = {}) {
    this.headers = setCartHeaders(token)
  }

  async fetch (token) {
    if (isNil(token)) {
      notifyBugsnag('Cart token is not provided')
      return {}
    }

    const url = `/v2.5/carts/${token}`
    try {
      const { data } = await this.client.get(url, {
        params: {
          include: 'line_items.product.variants,line_items.sample,line_items.sample.variants'
        },
        headers: this.headers
      })

      return data
    } catch (error) {
      notifyBugsnag(error)
      return {}
    }
  }

  async fetchCartPopup () {
    const token = get(vueSupport, 'cart.token', null)
    const url = `/v2.5/carts/${token}/bag`
    const { data } = await this.client.get(url, { headers: this.headers })

    return data
  }

  async fetchLineItemsCount () {
    const token = get(vueSupport, 'cart.token', null)
    const url = `/v2.5/carts/${token}/items_count`
    const { data } = await this.client.get(url, { headers: this.headers })

    return data
  }

  async createLineItem ({
    variantId = 0,
    quantity = 1,
    searchQueryId = ''
  }) {
    const url = `${this.url}/line_items`
    let response

    try {
      response = await this.client.post(url, {
        line_items: {
          variant_id: variantId,
          quantity,
          search_query_id: searchQueryId
        }
      }, {
        params: {
          include: 'line_items.product.variants,line_items.sample,line_items.sample.variants'
        },
        headers: this.headers
      })
    } catch (error) {
      console.log('error', error)
      return { success: false, data: get(error, 'response.data.errors[0]') }
    }

    return { success: true, data: response.data }
  }

  async addLineItemToBag ({
    variantId = 0,
    quantity = 1,
    searchQueryId = ''
  }) {
    const url = `/v2.5/carts/${vueSupport.cart.token}/line_items/add_to_bag`
    let response

    try {
      response = await this.client.post(url, {
        line_items: {
          variant_id: variantId,
          quantity,
          search_query_id: searchQueryId
        }
      }, {
        headers: this.headers
      })
    } catch (error) {
      return { success: false, data: { meta: { message: get(error, 'response.data.errors[0].message'), force: true } } }
    }

    return { success: true, data: response.data }
  }

  // method currently not used
  async updateLineItem ({
    lineItemId,
    variantId = 0,
    quantity = 1
  }) {
    const url = `${this.url}/line_items/${lineItemId}`

    const { data } = await this.client.put(url, {
      line_items: {
        variant_id: variantId,
        quantity
      }
    }, {
      params: {
        include: 'line_items.product.variants,line_items.sample,line_items.sample.variants'
      },
      headers: this.headers
    })

    return data
  }

  async removeLineItem (lineItemId) {
    const url = `${this.url}/line_items/${lineItemId}`

    const { data } = await this.client.delete(url, {
      params: {
        include: 'line_items.product.variants,line_items.sample,line_items.sample.variants'
      },
      headers: this.headers
    })

    return data
  }

  async createMultipleLineItems ({
    variantIds = []
  }) {
    const url = `${this.url}/line_items/create_multiple`

    const { data } = await this.client.post(url, {
      line_items: {
        variant_ids: variantIds
      }
    }, {
      params: {
        include: 'line_items.product.variants,line_items.sample,line_items.sample.variants'
      },
      headers: this.headers
    })

    return data
  }

  async createRedeemable ({
    redeemableId
  }) {
    const url = `${this.url}/redeemables`

    const { data } = await this.client.post(url, {
      cart: {
        redeemable_id: redeemableId
      }
    }, {
      params: {
        include: 'line_items.product.variants,line_items.sample,line_items.sample.variants'
      },
      headers: this.headers
    })

    return data
  }
}

export default Cart
